import * as ackeeTracker from 'ackee-tracker'
import { consola } from 'consola';

let instance: any = null;

if (typeof navigator !== 'undefined') {
  instance = ackeeTracker.create('https://site-analytics.izion24.com.vn', {
    ignoreLocalhost: false,
    ignoreOwnVisits: false,
    detailed: false,
  });
}

export const useEventTracking = (eventId: string, key: string) => {
  if (instance) {
    instance.action(eventId, {
      key: key,
      value: 1,
    });
  } else {
    consola.warn('Ackee tracking is not available on the server-side.');
  }
};

// Download App
export const DOWNLOAD_EVENT_ID: string = "1b46354c-cd26-4c2c-b347-1e813299a1d3";
export const DOWNLOAD_APP_BY_LINK_KEY: string = "Link";
export const DOWNLOAD_APP_ON_APP_STORE_KEY: string = "Apple Store";
export const DOWNLOAD_APP_ON_GOOGLE_PLAY_KEY: string = "Google Play";

export const DOWNLOAD_E_BOOK_EVENT_ID: string = "5632c25b-b71d-45e5-930b-393005a3b3af";
export const DOWNLOAD_E_BOOK_KEY: string = "Download E-book";

export const CHECK_CONTRACT_EVENT_ID: string = "2b9f1889-2548-4d35-82b1-c421bd1151a4";
export const CHECK_CONTRACT_KEY: string = "Check Contract";

export const VIEW_POLICIES_EVENT_ID: string = "6446cbe7-28ec-40c1-910a-2327bba5b06c";
export const VIEW_POLICIES_KEY: string = "View Policies";

export const INSURANCE_ADVISE_EVENT_ID: string = "333e4a2a-a536-4747-a6b5-8c8c430c6a4b";
export const INSURANCE_ADVISE_KEY: string = "Insurance Advise";

// Social
export const SOCIAL_EVENT_ID: string = "8f8660bc-2560-4ac7-a5ff-498197c4a607";
export const SOCIAL_FACEBOOK_KEY: string = "Facebook";
export const SOCIAL_MESSENGER_KEY: string = "Messenger";
export const SOCIAL_ZALO_KEY: string = "Zalo";
export const SOCIAL_YOUTUBE_KEY: string = "Youtube";
export const SOCIAL_TIKTOK_KEY: string = "Tiktok";

export const REGISTER_ACCOUNT_IZION24_EVENT_ID: string = "e2584452-a5af-4bfa-84ca-e3ecaafc5de5";
export const REGISTER_ACCOUNT_IZION24_KEY: string = "Register Account Izion24";

export const NEWSLETTER_SIGN_UP_EVENT_ID: string = "b225667c-29d7-4f9c-8576-f331e7e12e11";
export const NEWSLETTER_SIGN_UP_KEY: string = "Newsletter Sign Up";

export const CUSTOMER_SUPPORT_CONTACT_EVENT_ID: string = "e02f0300-ba4a-46f4-8c77-ce8d855581a7";
export const PRODUCT_ADVISE_CONTACT_EVENT_ID: string ="6ea256fe-ae3e-4212-be0a-56e386dc249a";

export const NEWS_EVENT_ID: string = "8d6f8cba-9349-4dfb-8d01-c421c2a271dc";

export const FAQS_EVENT_ID: string = "4a3b26b5-c00c-4bc2-9da9-85cb0f723df8";
